import { categories } from "./categories";
import { destinations } from "./destinations";

export default {
  title: "Best in Travel",
  description:
    "�ϰ������ϲʿ�����¼'s Best in Travel celebrates 30 incredible destinations for 2025. Discover the top countries, regions and cities around the world, chosen by our well-traveled team.",
  url: "https://lonelyplanet.com/best-in-travel",
  media: [
    {
      type: "video",
      orientation: "landscape",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-1.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-1.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-1.webm",
      },
    },
    {
      type: "video",
      orientation: "landscape",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-2.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-2.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-2.webm",
      },
    },
    {
      type: "video",
      orientation: "landscape",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-3.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-3.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-3.webm",
      },
    },
    {
      type: "video",
      orientation: "landscape",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-4.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-4.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-4.webm",
      },
    },
    {
      type: "video",
      orientation: "landscape",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-5.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-5.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-5.webm",
      },
    },
    {
      type: "video",
      orientation: "landscape",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-6.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-6.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-6.webm",
      },
    },
    {
      type: "video",
      orientation: "landscape",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-7.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-7.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-7.webm",
      },
    },
    {
      type: "video",
      orientation: "portrait",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-1.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-1.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-1.webm",
      },
    },
    {
      type: "video",
      orientation: "portrait",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-2.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-2.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-2.webm",
      },
    },
    {
      type: "video",
      orientation: "portrait",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-3.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-3.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-3.webm",
      },
    },
    {
      type: "video",
      orientation: "portrait",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-4.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-4.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-4.webm",
      },
    },
    {
      type: "video",
      orientation: "portrait",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-5.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-5.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-5.webm",
      },
    },
    {
      type: "video",
      orientation: "portrait",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-6.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-6.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-6.webm",
      },
    },
    {
      type: "video",
      orientation: "portrait",
      src: {
        poster:
          "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-7.png",
        mp4: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-7.mp4",
        webm: "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-portrait-7.webm",
      },
    },
  ],
  meta: {
    title: "Best Destinations to Travel in 2025 - �ϰ������ϲʿ�����¼",
    description:
      "These are our picks for the 30 best places to travel in 2025.",
    image:
      "https://lonelyplanetstatic.imgix.net/marketing/best-in-travel/2025/videos/main-page/bit-landscape-1.png",
    url: "/best-in-travel",
    canonicalUrl: "/best-in-travel",
  },
  categories: [...categories],
  destinations: [...destinations],
};
